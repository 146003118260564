import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { HeatpumpsTab } from "../Heatpumps";


export function AssignHPsToOrgUsersButton({
  handleOpen
}) {
  return (
    <div>
      <Button variant="text" color="primary" onClick={handleOpen}>
        Assign Heatpumps to User
      </Button>
    </div>
  )
}

export function AssignHPsToOrgUsers({
  organization,
  canAssignHPs,
  canRemoveHPs,
  organizationUserId,
  handleOpen,
  handleClose,
  open,
}) {
  return (
    <div>
      <AssignHPsToOrgUsersModal
        open={open}
        onClose={handleClose}
        organization={organization}
        canAssignHPs={canAssignHPs}
        canRemoveHPs={canRemoveHPs}
        organizationUserId={organizationUserId}
      />
    </div>
  );
}

export function AssignHPsToOrgUsersModal(props: {
  open: boolean;
  onClose: () => void;
  organization: any;
  canAssignHPs: boolean;
  canRemoveHPs: boolean;
  organizationUserId: string;
}) {
  const { open, onClose, organization, canAssignHPs, canRemoveHPs, organizationUserId } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={Paper}
        fullWidth
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Assign HPs to Organization User
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ py: 4 }}>
          <Box>
            <HeatpumpsTab
              organization={organization}
              canAssignHPs={canAssignHPs}
              canRemoveHPs={canRemoveHPs}
              organizationUserId={organizationUserId}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
