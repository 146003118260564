import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Outlet } from "react-router-dom";
import { useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { API } from "aws-amplify";
import { useHeatpumpData } from "../../../util/authorizer/useHeatpumpData";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HeatPump from "@mui/icons-material/HeatPump";
import Checkbox from "@mui/material/Checkbox";
import SimCardIcon from "@mui/icons-material/SimCard";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import Save from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import NoDataIcon from "@mui/icons-material/InboxOutlined";

interface Inputs {
  hpIds: {
    [key: string]: boolean;
  };
}

enum ViewType {
  LIST = "list",
  SELECT = "select",
}

const patchOrgAssignedHPs = async (organization, heatpumps) => {
  const newData = {
    id: organization.sk,
    heatpumps,
  };
  const apiName = "ThermonovaAPI";
  const path = "/organization/update";
  await API.post(apiName, path, {
    body: newData,
  });
};

const assignHPs = async (organization, heatpumpIds, organizationUserId) => {
  const newData = {
    organizationId: organization.sk,
    heatpumpIds,
    organizationUserId,
  };
  const apiName = "ThermonovaAPI";
  const path = "/organization/assign-hp";
  await API.post(apiName, path, {
    body: newData,
  });
};

export function HeatpumpsTab({
  organization,
  organizationUserId = null,
  canAssignHPs,
  canRemoveHPs,
}) {
  const [activeView, setActiveView] = useState({
    view: ViewType.LIST,
    meta: null,
  });
  const {
    data: heatpumps,
    searchQuery,
    setSearchQuery,
    allHPs,
  } = useHeatpumpData();
  const { mutate } = useSWRConfig();
  const handleActionClick = (action, node) => {
    switch (action) {
      case "delete":
        const newData = organization.heatpumps?.filter(hp => {
          if (organizationUserId) {
            return (hp.sk !== node.id || hp.organizationUserId !== organizationUserId) || 
                   (!hp.organizationUserId);
          }
          return hp.sk !== node.id;
        });
        // console.log({
        //   action: organizationUserId ? 'Removing HP from user' : 'Removing HP from organization and all users',
        //   originalHPs: organization?.heatpumps,
        //   filteredHPs: newData,
        //   targetHP: node.id,
        //   organizationUserId
        // });
        patchOrgAssignedHPs(organization, newData).then(() => {
          mutate("organizations");
          mutate(organization.sk);
        });
        break;
      default:
        console.error("Unknown action", action);
    }
  };

  const assignedHPs = useMemo(() => {
    if (organizationUserId) {
      return organization?.heatpumps?.filter((hp) => hp.organizationUserId === organizationUserId);
    }
    return organization?.heatpumps?.filter((hp) => !hp.organizationUserId);
  }, [organization, organizationUserId]);

  const availableHeatpumps = useMemo(() => {
    if (!heatpumps) return [];
    if (organizationUserId) {
      const organizationHeatpumpIds = organization?.heatpumps?.map((hp) => hp.sk) || [];
      return heatpumps?.filter((hp)=> organizationHeatpumpIds.includes(hp.sk));
    }
    return heatpumps;
  }, [organization,heatpumps, organizationUserId]);
  
  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
        {activeView.view === ViewType.LIST && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            {canAssignHPs && (
              <Box component="section" sx={{ p: 2 }}>
                <Tooltip title="Add Company" placement="top">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setActiveView({
                        view: ViewType.SELECT,
                        meta: null,
                      });
                    }}
                  >
                    <AddIcon style={{ fontSize: 20, marginRight: 5 }} />
                    Assign Heatpump
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Stack>
        )}
        <Box sx={{ height: "80vh", padding: 2 }}>
          {activeView.view === ViewType.LIST && (
            <HPGrid
              data={assignedHPs}
              onActionClick={handleActionClick}
              heatpumps={allHPs}
              canRemoveHPs={canRemoveHPs}
            />
          )}
          {activeView.view === ViewType.SELECT && (
            <AssignHPForm
              onSuccessfulSubmit={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              onCancel={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              organization={organization}
              heatpumps={availableHeatpumps}
              assignedHPs={assignedHPs}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              organizationUserId={organizationUserId}
            />
          )}
        </Box>
        <Outlet />
      </Paper>
    </div>
  );
}

const ACTIONS = [
  {
    value: "delete",
    label: "delete",
    icon: <DeleteIcon />,
  },
];

const buildColumns = ({ onActionClick, canRemoveHPs }) => {
  const columns: GridColDef[] = [
    { field: "sk", headerName: "Serialnumber", width: 200 },
    { field: "Name", headerName: "Name", width: 300 },
    { field: "Model", headerName: "Model", width: 200 },
    { field: "GatewayID", headerName: "Gateway ID", width: 200 },
    {
      field: "SimID",
      headerName: "Simcard",
      width: 70,
      renderCell: (cellValues) => {
        let icon;
        let ToolTiptext;
        if (cellValues.row.SimID === "No Modem") {
          ToolTiptext = cellValues.row.SimID;
          icon = <SignalCellularConnectedNoInternet0BarIcon />;
        } else if (cellValues.row.SimID === "") {
          ToolTiptext = "No Simcard";
          icon = <SimCardIcon color="primary" />;
        } else {
          ToolTiptext = cellValues.row.SimID;
          icon = <SimCardIcon color="success" />;
        }
        return (
          <Tooltip title={ToolTiptext}>
            <IconButton>{icon}</IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      sortable: false,
      renderCell: (row) => {
        return (
          <>
            <Box sx={{ p: 2 }}>
              {ACTIONS?.filter((action) => {
                if (action.value === "delete") {
                  return canRemoveHPs;
                }
                return true;
              }).map((action) => (
                <IconButton
                  key={action.value}
                  onClick={() => {
                    onActionClick(action.value, row);
                  }}
                  size="small"
                >
                  <Tooltip title={action.label} placement="top">
                    {action.icon}
                  </Tooltip>
                </IconButton>
              ))}
            </Box>
          </>
        );
      },
    },
  ];
  return columns;
};

export function HPGrid({
  data,
  onActionClick,
  heatpumps,
  canRemoveHPs,
}) {
  const rows = useMemo(() => {
  
    return (
      data?.map((hp) => {
        const heatpump = heatpumps?.find((h) => h.sk === hp.sk);
        return {
          ...hp,
          ...heatpump,
        };
      }) || []
    );
  }, [data, heatpumps]);
  return (
    <DataGridPro
      rows={rows}
      columns={buildColumns({
        onActionClick,
        canRemoveHPs,
      })}
      getRowId={(row) => row.sk}
      initialState={{
        pinnedColumns: {
          left: ["company"],
          right: ["actions"],
        },
      }}
    />
  );
}

interface AssignHPFormProps {
  onSuccessfulSubmit: () => void;
  onCancel: () => void;
  organization: any;
  assignedHPs: any;
  heatpumps: any;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  organizationUserId: string;
}

export function AssignHPForm(props: AssignHPFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { heatpumps, searchQuery, setSearchQuery, assignedHPs, organizationUserId } = props;
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const { mutate } = useSWRConfig();
  const { onSuccessfulSubmit, organization } = props;

  const mode = "edit";

  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      hpIds: {},
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      setBusy(true);
      setError(null);
      try {
        const hpIds = Object.keys(data?.hpIds)
          ?.map((key) => (data?.hpIds[key] ? key : undefined))
          ?.filter((key) => key);
        await assignHPs(organization, hpIds, organizationUserId);
        mutate("organizations");
        mutate(organization.sk);
        onSuccessfulSubmit();
        enqueueSnackbar("Heat pumps assigned", {
          variant: "success",
        });
      } catch (error) {
        const errorText = error.message || error;
        setError(errorText);
        enqueueSnackbar("Error: " + errorText, {
          variant: "error",
        });
      } finally {
        setBusy(false);
      }
    };
    execute();
  };

  const availableHeatpumps = useMemo(() => {
    if (!heatpumps) return [];

    const assignedHeatpumpIds =
      assignedHPs?.map((hp) => hp.sk) || [];
    return heatpumps.filter((hp) => !assignedHeatpumpIds.includes(hp.sk));
  }, [heatpumps, assignedHPs]);

  const filteredHeatpumps = useMemo(() => {
    if (!availableHeatpumps) return [];

    if (!searchQuery) return availableHeatpumps;

    const query = searchQuery.toLowerCase();
    return availableHeatpumps.filter(
      (hp) =>
        hp.Name?.toLowerCase().includes(query) ||
        hp.Serialnumber?.toLowerCase().includes(query) ||
        hp.sk?.toLowerCase().includes(query)
    );
  }, [availableHeatpumps, searchQuery]);

  const renderEmptyState = () => {
    if (!availableHeatpumps?.length) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
            color: "text.secondary",
          }}
        >
          <NoDataIcon sx={{ fontSize: 24, mb: 2, color: "action.disabled" }} />
          <Typography variant="body2">No Heat Pumps Available</Typography>
        </Box>
      );
    }

    if (!filteredHeatpumps.length) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
            color: "text.secondary",
          }}
        >
          <NoDataIcon sx={{ fontSize: 64, mb: 2, color: "action.disabled" }} />
          <Typography variant="h6">No Results Found</Typography>
          <Typography variant="body2">
            Try adjusting your search to find what you're looking for.
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ py: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={8}>
              <DialogContentText>
                Select the heatpump you want to assign to{" "}
                <b>{organization?.name}</b>.
              </DialogContentText>
            </Grid>
            <Grid item xs={6} md={8}>
              <SearchSection
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  maxHeight: 300,
                  overflow: "auto",
                  minHeight: 100,
                }}
              >
                {renderEmptyState()}
                {filteredHeatpumps.length > 0 &&
                  filteredHeatpumps.map((hp) => {
                    const labelId = `checkbox-list-label-${hp.sk}`;
                    return (
                      <ListItem
                        key={hp.sk}
                        secondaryAction={
                          <IconButton edge="end" aria-label="comments">
                            <HeatPump />
                          </IconButton>
                        }
                        disablePadding
                      >
                        <Controller
                          name={`hpIds.${hp.sk}`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <ListItemButton
                                role={undefined}
                                onClick={() => {
                                  field.onChange({
                                    target: {
                                      value: !Boolean(field.value),
                                    },
                                  });
                                }}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    checked={Boolean(field.value)}
                                    {...field}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    <span>
                                      {hp.Name} - {hp.Serialnumber}
                                    </span>
                                  }
                                />
                              </ListItemButton>
                            </>
                          )}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>

            {error && (
              <Grid item xs={12} md={8}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  props.onCancel();
                }}
                disabled={busy}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                startIcon={busy ? <CircularProgress size={20} /> : <Save />}
                disabled={busy}
              >
                {mode === "edit" ? "Save" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}

type SearchSectionProps = {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
};

function SearchSection(props: SearchSectionProps) {
  const { searchQuery, setSearchQuery } = props;

  return (
    <TextField
      label="Search"
      variant="outlined"
      sx={{ mb: 2, bt: 2 }}
      placeholder="Search with name or serial number"
      value={searchQuery}
      size="small"
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
}
