import { useState, useMemo } from "react";
import UsersView from "../../Users/Users";
import { Box, Paper, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(1),
  boxShadow: '0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)',
  height: '100%',
  backgroundColor: '#FFFFFF',
}));

const PageHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

interface UsersProps {
  type: "end-users" | "thermonova-users";
}

export function Users({ type }: UsersProps) {
  const [userData, setUserData] = useState([]);

  const filteredUsers = useMemo(() => {
    if (type === "end-users") {
      const filtered = userData.filter((user: any) => 
        !user.col1?.includes('@thermonova.dk') && 
        !user.col1?.includes('@thermonova.com') && 
        !user['custom:user_role']
      );
      return filtered;
    }
    const filtered = userData.filter((user: any) => 
      (user.col1?.includes('@thermonova.dk') || user.col1?.includes('@thermonova.com')) &&
      !user['custom:user_role']
    );
    return filtered;
  }, [userData, type]);

  const getPageTitle = () => {
    if (type === "end-users") {
      return "End-Users";
    }
    return "Thermonova Users";
  };

  const getPageDescription = () => {
    if (type === "end-users") {
      return "View and manage end-user accounts that are not associated with any company or Thermonova";
    }
    return "View and manage Thermonova accounts (@thermonova.dk, @thermonova.com)";
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 0 }}>
        <PageHeader>
          <Typography 
            variant="h5" 
            fontWeight={600} 
            color="text.primary"
            sx={{ fontSize: '1.25rem' }}
          >
            {getPageTitle()}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.875rem',
              lineHeight: 1.5,
            }}
          >
            {getPageDescription()}
          </Typography>
        </PageHeader>
        
        <StyledPaper elevation={0}>
          <UsersView 
            rows={filteredUsers} 
            setRows={setUserData} 
            height={800} 
          />
        </StyledPaper>
      </Box>
    </Container>
  );
}
